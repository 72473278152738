<template lang="pug">
	.component
		ContainerForData.mt-24(width="100%" :isLoading="isLoading")
			template(#header-left)
				.flex.items-center
					p Online Store
					Tooltip.ml-8(
						v-if="false"
						trigger="hover"
					)
						template(slot="reference")
							HelpCircle.icon
						span 'Tooltip'
			template(#header-right)
				.size-blocks.flex.ml-auto
					span Presence
					span Presence And Completeness
			template.test(#data)
				.flex.flex-col(
					v-for="(item, index) in response[activeGroup]"
					style="width: 100%"
				)
					.header.flex.items-center.justify-between
						.title.ml-8 {{ item.name }}
						.size-blocks.flex.ml-auto
							span {{ getPresence(item.rules) }}
							span {{ item[`noCompliant${activeTab}`] }}
					.ml-auto.flex.items-center.justify-center.underline.mb-16.mr-16(@click="toggleDetails(index)") Details
					.table.failures(:ref="`failures_${index}`")
						.row.row__header.flex.flex
							.row__title.flex.items-center.justify-center {{ item.name }} {{ item[`noCompliant${activeTab}`] }}
							.row__data.flex.ml-auto
								.row__data-fails.flex.items-center.justify-end Fails
						.row.row__not__header.flex(v-for="(item, index) in getMessage(item.rules)")
							.row__title.flex.items-center.justify-center {{ item.message }}
							.row__data.flex.ml-auto
								.row__data-fails.flex.items-center.justify-end {{ item.countRule }}
							//- .row__accordion(:ref="`accordion${index}`")
							//- 	span Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, voluptatibus!

		ContainerForData.mt-24(width="100%" :isLoading="isLoading")
			template(#header-left)
				.flex.items-center
					p All Products
					Tooltip.ml-8(
						v-if="false"
						trigger="hover"
					)
						template(slot="reference")
							HelpCircle.icon
						span 'Tooltip'
			template(#header-right)
				Search.mr-12(v-model="search" @change="$emit('search', search)")
			template(#data)
				Table.mx-12(v-bind="tableOptions")
					template(#product="{item, index, value}")
						a(
							v-if="item.productUrl"
							:href="item.productUrl"
							target="_blank"
						) {{ item.productName }}
						.name(v-else) {{ item.productName }}
						div
							strong RPC
							span.ml-8 {{ item.rpc }}
						div
							strong UPC
							span.ml-8 {{ item.upc }}

			template(
				v-if="hasPagination"
				#pagination
			)
				.element-container__content.flex.items-center.justify-center.mt-24.mb-24
					pagination(
						:total="productsLength"
						:current="current"
						:limit="limit"
						@change-page="current = $event"
					)

</template>

<script>
import Tooltip from '@/components/Elements/Tooltip.vue'
import Tabs from "@/components/Nestle/Tabs"
import ContainerForData from "@/components/Nestle/ContainerForData"
import BarChart from "@/components/Chart/BarChart"
import HelpCircle from 'vue-material-design-icons/HelpCircle.vue'
import Search from "./Search"
import Table from "../Table/Table"
import Pagination from "@/components/Pagination/Pagination.vue"

export default {
	name: "Issue",
	components: {
		Table,
		Search,
		Tabs,
		Tooltip,
		ContainerForData,
		BarChart,
		HelpCircle,
		Pagination,
	},
	props: {
		response: {
			type: Object,
			default: () => {},
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		activeGroup: {
			type: String,
			default: 'stores',
		},
		activeTab: {
			type: String,
			default: 'Sku',
		},
	},
	data() {
		return {
			search: '',
			current: 1,
			limit: 20,
			offset: 0,
			groupMapping: {
				Store: {
					name: 'stores',
				},
				Brand: {
					name: 'brands',
				},
				Category: {
					name: 'category',
				},
			},
		}
	},
	computed:{
		productsLength () {
			return this.response?.products.length
		},
		hasPagination() {
			if (!this.response?.products) return
			return this.response?.products.length / this.limit > 1
		},
		tableDataDefault() {
			return this.response.products.slice(this.offset, this.offset + this.limit)
		},
		tableColumns() {
			return [
				{
					title: 'Online Store',
					width: 60,
					cellClass: 'justify-center',
					value: (item) => {
						return item.storeName
					}
				},
				{
					title: 'Category',
					width: 60,
					cellClass: 'justify-center',
					value: (item) => {
						return item.categoryName
					}
				},
				{
					title: 'Brand',
					width: 40,
					cellClass: 'justify-center',
					value: (item) => {
						return item.brandName
					}
				},
				{
					title: 'Description',
					width: 100,
					slot: 'product',
					cellClass: 'justify-center',
					value: (item) => {
						return item.description ?? ''
					}
				},
				{
					title: 'Fail Message',
					width: 80,
					cellClass: 'justify-center',
					value: (item) => {
						return item.message
					}
				},
			]
		},
		tableOptions() {
			return {
				sort: {field: 'name', order: 'desc'},
				columns: this.tableColumns,
				data: this.tableDataDefault,
			}
		},
	},
	methods: {
		getPresence(rules) {
			const result = rules.reduce((acc, value) => acc += Number(value.countRule > 0), 0)
			return result
		},
		getMessage(rules) {
			return rules.filter(item => item.countRule > 0)
		},
		toggleDetails(index) {
			const currentDisplayStyle = this.$refs[`failures_${index}`][0].style.display
			const newValue = currentDisplayStyle === 'block' ? 'none' : 'block'
			this.$refs[`failures_${index}`][0].style.display = newValue
		},
		open(i) {
			const el = 	this.$refs[`accordion${i}`][0]

			// если будут большие объемы данных лучше убрать во избежание лишней нагрузки (автозакрытие всех контейнеров аккордиона)
			Object.values(this.$refs).forEach((el=>{
				el[0].style.display = 'none'
			}))

			window.getComputedStyle(el).display === 'none' ? this.$refs[`accordion${i}`][0].style.display = 'block' : this.$refs[`accordion${i}`][0].style.display = 'none'
		}
	},

	watch: {
		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.row {
	&__title {
		padding: 12px;
	}
	&__data {
		&-fails {
			font-weight: bold;
		}
		&-fails, &-views {
			width: 150px;
			padding: 12px;
		}
	}
	&__accordion {
		display: none;
		background-color: color(gray-300);
		padding: 12px;
	}
}

.underline {
	text-decoration: underline;
	cursor: pointer;
	color: black !important;
	&:hover {
		color: blue !important;
	}
}

.row__header {
	background-color: color(gray-500);
	font-weight: bold;
}

.row__not__header {
	&:hover {
		background-color: color(gray-400);
		color: red;
	}
}

.size-blocks {
	height: 40px;
	font-weight: 700;
	color: color(gray-700);
	//width: 631px;
	span {
		border-left: 1px color(gray-400) solid;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		&:nth-child(1){
			width: 150px;
		}
		&:nth-child(2){
			width: 250px;
		}
	}
}
.header {
	width: 100%;
	height: 40px;
	border: 1px color(gray-400) solid;
	margin-bottom: 20px;
	-webkit-box-shadow: 0 5px 8px -3px rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 0 5px 8px -3px rgba(34, 60, 80, 0.2);
	box-shadow: 0 5px 8px -3px rgba(34, 60, 80, 0.2);
	.title{
		font-weight: 600;
		color: color(gray-700);
	}
	.size-blocks {
		span {
			&:nth-child(2) {
				background-color: red;
				color: white;
			}
		}
	}
}
::v-deep.element-container__content{
	flex-direction: column;
	padding: 0;
}
::v-deep.element-container__title-right{
	margin: 0 0 0 auto;
}
::v-deep.chart {
	width: 100%;
}
.failures {
	display: none;
}
select{
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus{
		outline: none;
	}
}
.component {
	max-width: 1280px;
	margin: 30px auto 0;
}
</style>
